<template>
  <v-container>
    <v-row
        justify="start"
        align="center"
        class="mt-4"
    >
      <div
          style="width: 150px"
          class="mr-10"
      >
        <v-select
            label="Customer"
            :items="tripbitsCustomerList"
            v-model="currentTripbitsCustomer"
            item-text="label"
            item-value="id"
            return-object
            @change="changeCustomer"
        />
      </div>

      <div
          class="mr-10"
      >
        <v-btn
            :color="!isFiltersChanged ? 'info' : 'warning' "
            class="ma-2"
            @click="getData"
            :outlined="!isFiltersChanged"
        >
          {{ isFiltersChanged ? 'Update' : 'Updated ' }}
        </v-btn>
      </div>
    </v-row>
    <v-row>
      <v-col
          :cols="6"
      >
        <TablePaginatedToggleButton
            label="Your Destinations"
            :main-list="geotagsComputed"
            :is-global-percent="false"
            :override-headers="destinationsOverrideHeaders"
            @setActiveItem="setActiveGeotag"
            is-mark-active-item
        />
      </v-col>
      <v-col
        :cols="6"
      >
        <TablePaginatedToggleButton
            label="Points of interests"
            :main-list="poisComputed"
            :override-headers="poisOverrideHeaders"
            :is-global-percent="false"
        >
          <template v-slot:item.videos="{ item }">
            <v-row
                no-gutters
                style="flex-wrap: nowrap"
            >
              <v-col
                  v-for="(video, i) in item.other.videos"
                  :key="i"
              >
                <v-card
                      class="video-card mx-1"
                      @click="showVideo(video.vimeo_uri)"
                      :max-width="100"
                  >
                    <v-img v-if="!!video.preview_url"
                           :src="video.preview_url"
                           :max-width="100"
                    />
                      <v-card-actions
                          class="card-actions"
                      >
                        <v-btn
                            color="orange"
                            small
                            icon
                            @click.stop="downloadVideo(video.vimeo_uri)"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{on, attr}">
                              <v-icon
                                v-bind="attr"
                                v-on="on"
                              >
                                mdi-download
                              </v-icon>
                            </template>
                            download video
                          </v-tooltip>
                        </v-btn>
                      </v-card-actions>
                  </v-card>
              </v-col>
            </v-row>

          </template>
        </TablePaginatedToggleButton>
      </v-col>
    </v-row>
    <DialogVideoPlayer
        :show.sync="dialogs.videoShowDialog.isOpened"
        :src.sync="dialogs.videoShowDialog.data.src"
    />
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
// import axios from 'axios';
import TablePaginatedToggleButton from '../components/TablePaginatedToggleButton.vue';
import DialogVideoPlayer from '../components/dialogs/DialogVideoPlayer.vue';
import manageDialogsMixin from '../mixins/manageDialogsMixin';

export default {
  name: 'VideoAssets',
  components: {
    DialogVideoPlayer,
    TablePaginatedToggleButton,
  },

  mixins: [
    manageDialogsMixin,
  ],

  data: () => ({
    tripbitsCustomerList: [],

    currentTripbitsCustomer: {
      id: null,
      name: '',
    },

    isFiltersChanged: true,

    tripbitsCustomerGeotags: [],

    selectedGeotagIndex: 0,

    destinationsOverrideHeaders: [
      {
        text: '', value: 'label', tabs: ['every'],
      },
    ],

    poisOverrideHeaders: [
      {
        text: '', value: 'label', tabs: ['every'],
      },
      {
        text: 'Videos', value: 'videos', tabs: ['default'],
      },
    ],

    dialogs: {
      videoShowDialog: {
        isOpened: false,
        data: {
          src: '',
        },
        defaults: {
          src: '',
        },
      },
    },
  }),

  async created() {
    await this.initPage();
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    async initPage() {
      await this.fetchTripbitsCustomerList();

      this.currentTripbitsCustomer = this.tripbitsCustomerList.length
        ? this.tripbitsCustomerList[0]
        : {
          id: null,
          name: '',
        };

      if (this.hasAllAbilities([this.$constants.ABILITY.CUSTOMER])) {
        await this.getData();
      }
    },

    async fetchTripbitsCustomerList() {
      const response = await this.$requestHelpers
        .get('api/admin/customer/getUserTripbitsCustomerList');

      if (!response.data.length) {
        await this.$router.push({ name: 'root' });
      }

      this.tripbitsCustomerList = response.data.map(
        (el) => ({
          id: el.id,
          name: el.name,
          label: el.name,
        }),
      );
    },

    changeFilters(flag = true) {
      this.isFiltersChanged = flag;
    },

    async changeCustomer() {
      this.changeFilters();
    },

    setActiveGeotag(num) {
      this.selectedGeotagIndex = num;
    },

    getData() {
      this.$requestHelpers
        .post(
          'api/admin/customer/getUserCustomerGeotags',
          {
            tripbits_customer_id: this.currentTripbitsCustomer.id,
          },
        )
        .then((response) => {
          this.changeFilters(false);

          this.tripbitsCustomerGeotags = response.data;
        });
    },

    showVideo(vimeoUri) {
      this.$requestHelpers
        .post('api/admin/analytics/getTheHighestResolutionVimeoUri',
          {
            vimeo_uri: vimeoUri,
          }).then((response) => {
          this.openDialog('videoShowDialog', { src: response.data.result.uri });
        });
    },

    downloadVideo(vimeoUri) {
      this.$requestHelpers
        .post(
          'api/admin/analytics/getVimeoVideoDownloadLink',
          {
            vimeo_uri: vimeoUri,
          },
        ).then((response) => {
          this.$store.dispatch('overlay/showOverlay', true);

          const fileLink = document.createElement('a');

          fileLink.href = response.data.result.download_url;
          document.body.appendChild(fileLink);

          fileLink.onclick = () => {
            setTimeout(() => {
              this.$store.dispatch('overlay/showOverlay', false);
            }, 1500);
          };

          fileLink.click();
        });
    },
  },

  computed: {
    ...mapGetters({
      userId: 'auth/userId',
    }),

    geotagsComputed() {
      return this.tripbitsCustomerGeotags
        .filter((el) => el.pois.length)
        .map((el) => ({ id: el.id, label: el.name, pois: el.pois }));
    },

    poisComputed() {
      if (!this.geotagsComputed.length) {
        return [];
      }

      return this.geotagsComputed[this.selectedGeotagIndex].pois.map(
        (el) => ({ id: el.id, label: el.name, other: { videos: el.videos } }),
      );
    },
  },
};
</script>
<style scoped>
.card-actions {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}
</style>
